<template>
  <div id="twoEcharts" style=" height: 450px;margin-left: -50px;">
  </div>
</template>

<script>
  import * as echarts from "echarts";

  export default {
    name: "soilTempHistory",
    props: {
      landTemperList: {
        type: Array, // 数据类型根据实际情况进行调整
        required: true,
      },
    },
    watch: {
      landTemperList: {
        //immediate: true, // 立即执行一次
        handler(newValue) {

          if (newValue) {
            // 执行相应的操作，如数据渲染
            this.option.series[0].data = newValue
            this.getData()
          }
        },
      },
    },
    data() {
      return {
        option: {
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow"
            }
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: {
            type: 'time',
            // min: `${new Date(new Date().setHours(0, 0, 0, 0)).getFullYear()}-${String(new Date(new Date().setHours(0, 0, 0, 0)).getMonth() + 1).padStart(2, '0')}-${String(new Date(new Date().setHours(0, 0, 0, 0)).getDate()).padStart(2, '0')} ${String(new Date(new Date().setHours(0, 0, 0, 0)).getHours()).padStart(2, '0')}:${String(new Date(new Date().setHours(0, 0, 0, 0)).getMinutes()).padStart(2, '0')}:${String(new Date(new Date().setHours(0, 0, 0, 0)).getSeconds()).padStart(2, '0')}`,
            // max: `${new Date(new Date().setHours(23, 59, 59, 999)).getFullYear()}-${String(new Date(new Date().setHours(23, 59, 59, 999)).getMonth() + 1).padStart(2, '0')}-${String(new Date(new Date().setHours(23, 59, 59, 999)).getDate()).padStart(2, '0')} ${String(new Date(new Date().setHours(23, 59, 59, 999)).getHours()).padStart(2, '0')}:${String(new Date(new Date().setHours(23, 59, 59, 999)).getMinutes()).padStart(2, '0')}:${String(new Date(new Date().setHours(23, 59, 59, 999)).getSeconds()).padStart(2, '0')}`,

          },
          yAxis: {
            type: 'value',
            name: "土壤温度(℃)",
            axisLabel: {
              //在y轴的数据的值旁边拼接单位
              formatter: "{value} ℃",
            },
            axisTick: {},
            min: 0,
            max: 100
          },
          series: [
            {
              max: 100,
              min: 0,
              minInterval: 0,
              name: "当前温度",
              data: [],
              type: 'line',
              smooth:true,
              itemStyle : {
                color:{
                  type: 'linear',
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [{
                    offset: 0, color: '#17BE59' // 0% 处的颜色
                  }, {
                    offset: 1, color: '#17BE59' // 100% 处的颜色
                  }],
                  global: false // 缺省为 false
                }
              },
              lineStyle:{
                color: {
                  type: 'linear',
                  x: 0,
                  y: 0,
                  x2: 1,
                  y2: 1,
                  colorStops: [{
                    offset: 0, color: '#17BE59' // 0% 处的颜色
                  },{
                    offset: 0.5, color: '#17BE59' // 0% 处的颜色
                  }, {
                    offset: 1, color: '#17BE59' // 100% 处的颜色
                  }],
                  global: false // 缺省为 false
                }
              },
              areaStyle: {//区域填充渐变颜色
                color: {
                  type: 'linear',
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [{
                    offset: 0, color: 'rgba(23,190,89,0.39)' // 0% 处的颜色
                  }, {
                    offset: 1, color: 'rgba(255, 255, 255, 0.01)' // 100% 处的颜色
                  }],
                  global: false // 缺省为 false
                }
              }
            }
          ]
        },
      }
    },
    created() {
      if (this.landTemperList.length>0){
        this.option.series[0].data = this.landTemperList
        console.log('999',this.option.series[0].data)
      }
      console.log("1111111111")
    },
    mounted() {
      this.getData()
      console.log('5656', `${new Date(new Date().setHours(0, 0, 0, 0)).getFullYear()}-${String(new Date(new Date().setHours(0, 0, 0, 0)).getMonth() + 1).padStart(2, '0')}-${String(new Date(new Date().setHours(0, 0, 0, 0)).getDate()).padStart(2, '0')} ${String(new Date(new Date().setHours(0, 0, 0, 0)).getHours()).padStart(2, '0')}:${String(new Date(new Date().setHours(0, 0, 0, 0)).getMinutes()).padStart(2, '0')}:${String(new Date(new Date().setHours(0, 0, 0, 0)).getSeconds()).padStart(2, '0')}`);
      // console.log('5656', `${new Date(new Date().setHours(23, 59, 59, 999)).getFullYear()}-${String(new Date(new Date().setHours(23, 59, 59, 999)).getMonth() + 1).padStart(2, '0')}-${String(new Date(new Date().setHours(23, 59, 59, 999)).getDate()).padStart(2, '0')} ${String(new Date(new Date().setHours(23, 59, 59, 999)).getHours()).padStart(2, '0')}:${String(new Date(new Date().setHours(23, 59, 59, 999)).getMinutes()).padStart(2, '0')}:${String(new Date(new Date().setHours(23, 59, 59, 999)).getSeconds()).padStart(2, '0')}`);
    },
    methods: {
      getData() {

        console.log(this.option.series[0].data)
        let chartDom = document.getElementById("twoEcharts");
        let myChart = echarts.init(chartDom);
        myChart.setOption(this.option);
        window.addEventListener("resize", () => {
          if (myChart) {
            myChart.resize();
          }
        });
        this.option && myChart.setOption(this.option);
      },
    }
  }
</script>

<style scoped lang="scss">
  #twoEcharts {

  }
</style>
