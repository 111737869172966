<template>
    <div class="mod-config" style="display: flex;
        flex-direction: column;overflow: hidden;">
        <div style="width: 100%;height:80px;background: white;box-sizing: border-box;padding: 20px 0 0 48px;">
            <div class="sousuo" style="height: 41px;line-height: 41px; box-sizing: content-box;margin-bottom: 20px;">
                <span style="color: #333333;font-size: 16px;margin-right: 13px">基地名称</span>
                <el-select style="background-color: #E5E5E5;border: 1px solid #E5E5E5; border-radius: 4px;" v-model="id" filterable collapse-tags clearable  placeholder="全部">
                    <el-option
                            v-for="item in jidiName"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"/>

                </el-select>
                <span style="color: #333333;font-size: 16px;margin: 0 13px 0 19px;">大棚编号</span>
                <el-select style="background-color: #E5E5E5;border: 1px solid #E5E5E5; border-radius: 4px;" v-model="gid"  filterable collapse-tags clearable  :placeholder="gid==''?'请选择大棚':'全部'">
                    <el-option
                            v-for="item in dapengName"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"/>

                </el-select>
                <span style="color: #333333;font-size: 16px;margin: 0 13px 0 19px;">选择日期</span>
                <el-date-picker
                        v-model="selectDay"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        :picker-options="pickerOPtions"
                        value-format="yyyy-MM-dd"
                        format="yyyy-MM-dd">
                </el-date-picker>
                <el-button @click="chaxun()" style="margin-left: 25px; background: linear-gradient(0deg, #1ECC68, #14B651, #0E902F);border: 1px solid rgba(114,114,114,0.22);
                    border-radius: 4px; color: white;font-size: 16px; width: 100px; height: 40px;">
                    查询
                </el-button>
            </div>
        </div>

        <div style="height: 100%;overflow-y:scroll;	margin-top: 10px;position: absolute;left: 0;top: 85px;right: -17px;bottom: 0;">
            <div style="width:auto; height:527px;background:white;margin: 0 24px 0 24px; border-radius: 6px;padding-top: 25px;">
                <el-tabs style="width:auto;"  v-model="activeName" @tab-click="handleClick">

                    <el-tab-pane  :lazy="true"  style="padding-left: 20px !important;"  label="空气温度" name="airtemp" >
                        <airTemp   v-if="'airtemp' === activeName"     :temperList="temperList" ></airTemp>
                    </el-tab-pane>
                    <el-tab-pane :lazy="true"  label="土壤温度" name="soilTemp">
                        <soilTemp style="margin-left: -30px !important;" v-if="'soilTemp' === activeName" :landTemperList="landTemperList" ></soilTemp>
                    </el-tab-pane>
                    <el-tab-pane lazy  label="空气湿度" name="airhumidity">
                        <airHumidity style="margin-left: -30px !important;" v-if="'airhumidity' === activeName" :humidList="humidList" ></airHumidity>
                    </el-tab-pane>
                    <el-tab-pane lazy  label="土壤湿度" name="soilhumidity">
                        <soilHumidity style="margin-left: -30px !important;" v-if="'soilhumidity' === activeName" :landHumidList="landHumidList"  ></soilHumidity>
                    </el-tab-pane>
                    <el-tab-pane lazy  label="光照度" name="light">
                        <lightHumidity style="margin-left: -30px !important;" v-if="'light' === activeName"  :lightList="lightList" ></lightHumidity>
                    </el-tab-pane>
                    <el-tab-pane lazy label="二氧化碳浓度" name="co2concent">
                        <co2Humidity style="margin-left: -30px !important;" v-if="'co2concent' === activeName" :co2List="co2List" ></co2Humidity>
                    </el-tab-pane>
                    <el-tab-pane lazy  label="水肥浓度监测（PH值）" name="phconcent">
                        <waterPH  style="margin-left: -20px !important;" v-if="'phconcent' === activeName" :landPHList="landPHList" ></waterPH>
                    </el-tab-pane>
                </el-tabs>
            </div>
            <div style="min-height: 910px;width: auto;margin: 20px 24px 0 24px;background: white;border-radius: 4px;">
                <div >
                    <span style="width: 5px;height: 26px; background: #17BE59;border-radius: 2.5px;display: inline-block;float:left;margin: 18px 8px 0 24px;"></span>
                    <span style="display: inline-block;margin-top: 20px;">数据明细</span>
<!--                        <download-excel style="float:right;display: inline-block"-->
<!--                                class="export-excel-wrapper"-->
<!--                                :data="DetailsForm"-->
<!--                                :fields="json_fields"-->
<!--                                :header="title"-->
<!--                                name="数据明细.xls">-->
<!--                            &lt;!&ndash; 上面可以自定义自己的样式，还可以引用其他组件button &ndash;&gt;-->
<!--                            <el-button type="success" class="xiangqing" icon="el-icon-sold-out">导出</el-button>-->
<!--                        </download-excel>-->
                </div>
                <div style="margin: 25px 23px 20px 24px;">
                    <el-table
                            id='exportExcelBox'
                            :data="historyList.slice((pageIndex-1)*pageSize,pageIndex*pageSize)"
                            border
                            :row-class-name="tableRowClassName"
                            @selection-change="handleSelectionChange"
                            header-row-class-name="tableStyle"
                    >
                        <el-table-column width="50"
                                         type="selection">
                        </el-table-column>
                        <el-table-column width="60"
                                         type="index"
                                         label="序号"
                        >
                        </el-table-column>


                        <el-table-column
                                prop="humid"
                                label="空气湿度"
                        >
                        </el-table-column>
                        <el-table-column
                                prop="landTemper"
                                label="土壤湿度">
                        </el-table-column>
                        <el-table-column
                                         prop="co2"
                                         label="二氧化碳浓度">
                        </el-table-column>
                        <el-table-column
                                         prop="ariPre"
                                         label="空气温度">
                        </el-table-column>
                        <el-table-column
                                         prop="landTemper"
                                         label="土壤温度">
                        </el-table-column>
                        <el-table-column
                                         prop="light"
                                         label="光照度">
                        </el-table-column>
                        <el-table-column
                                         prop="landPH"
                                         label="水肥浓度(PH值)">
                        </el-table-column>

                        <el-table-column
                                prop="time"
                                label="时间"
                        >
                        </el-table-column>


                    </el-table>
                    <el-pagination
                            @size-change="sizeChangeHandle"
                            @current-change="currentChangeHandle"
                            :current-page="pageIndex"
                            :page-sizes="[pageSize,2*pageSize, 3*pageSize, 4*pageSize]"
                            :page-size="pageSize"
                            :total="historyList.length"
                            layout="total, sizes, prev, pager, next, jumper">
                    </el-pagination>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import airTemp from "@/components/echarts/airTempHistory";
    import soilTemp from "@/components/echarts/soilTempHistory";

    import airHumidity from "@/components/echarts/airHumidityHistory";
    import soilHumidity from "@/components/echarts/soilHumidityHistory";
    import lightHumidity from "@/components/echarts/lightHistory";
    import waterPH from "@/components/echarts/waterPHHistory";
    import co2Humidity from "@/components/echarts/co2History";
    /**
     * @method 创建一个表单并提交
     *  @param {String} url 提交的地址
     * @param {Object} params 提交的数据
     * @param {String} method 提交的方式
     */

    export default {
        name: "qiheHistoryDetails",
        data(){
            return{
                name: "",
                id: "",//基地id
                gid: "",//大棚id
                gidName: "",//基地名称
                selectDay:'',
                activeName: 'airtemp',
                props: {
                    iconFileName: {
                        type: String,
                        required: true
                    }
                },
                waringList:[{index:1},{index:2},{index:3},{index:4},{index:5},{index:6},{index:7},{index:8},{index:9},{index:10}],
                pageIndex: 1,
                pageSize: 10,
                temperList:[],
                landTemperList: [],//土壤温度
                humidList: [],//空气湿度
                co2List: [],//二氧化碳浓度
                lightList: [],//光照强度
                landPHList: [],//水肥PH值
                landHumidList: [],//土壤湿度
                selectedNum:0,//选中记录数量
                indexs:[],
                pickerOPtions: {
                    disabledDate(time){
                        return time.getTime()>=Date.now()
                    }
                },
                value:'',
                result:{},
                jidiName:[],//基地列表
                dapengName:[],//大棚列表
                start:'',//开始日期
                finish:'',//结束日期
                historyList:[],//数据明细

                title: "数据明细",
                json_fields: {
                    "时间":'time',
                    "空气湿度":'humid',
                    "土壤湿度":'landTemper',
                    "二氧化碳浓度":'co2',
                    "空气温度":'ariPre',
                    "土壤温度":'landTemper',
                    "光照度":'light',
                    "水肥浓度监测(PH值)":'landPH',

                },
                DetailsForm:[]
            }
        },
        components: {
            airTemp,
            airHumidity,
            soilHumidity,
            lightHumidity,
            soilTemp,
            waterPH,
            co2Humidity
        },
        created() {
            this.gidName = this.$route.query.name
            this.id = this.$route.query.id//基地id

        },
        mounted() {
            this.getDataNameList();
            this.$bas.$emit("suiji",'/qiheHistory')

        },

        methods:{

            getDataNameList(){
                this.$api.get('base/list', {}, result => {
                    this.jidiName = result.rows
                })
            },
            getDataPengList(newBid){
                this.$api.get('gHouse/getGreenhouseList?bid='+newBid, {}, result => {
                    // this.$api.get('gHouse/getGreenhouseList?bid=1', {}, result => {
                    this.dapengName = result.list
                    if (this.dapengName.length>0){
                        this.gid=this.dapengName[0].id
                        this.getData()
                    }
                })
            },
            getData() {
                this.$api.post('data/getTodayList', {bid: this.id,gid:this.gid,start:this.start,finish:this.finish}, result => {
                    this.result=result;
                    console.log("11111");
                    this.temperList = result.temperList
                    this.landTemperList = result.landTemperList
                    this.lightList = result.lightList
                    this.humidList = result.humidList
                    this.co2List = result.co2List
                    this.landPHList = result.landPHList
                    this.landHumidList = result.landHumidList
                    this.historyList=result.list.rows


                })
            },
            chaxun(){
                if (this.selectDay !=""&&this.selectDay!=null&&this.selectDay!=undefined){
                    let riqi=this.selectDay.slice(",");
                    this.start=riqi[0]+' 00:00:00'
                    this.finish=riqi[1]+' 23:59:59'
                    console.log('日期'+this.start+"-----"+this.finish);
                    console.log('日期'+this.selectDay);
                }else {
                    this.start="";
                    this.finish="";
                }
                this.getData()
            },
            handleClick(tab, event) {
                this.activeName=tab.name;
                console.log("名字"+this.activeName);
            },

            tableRowClassName({rowIndex}) {
                // if (rowIndex % 2 == 1) {
                //     return 'warning-row';
                // } else if (rowIndex === 3) {
                //     return 'success-row';
                // }
                return '';
            },
            // 每页数
            sizeChangeHandle(val) {
                this.pageSize = val
                this.pageIndex = 1
                // this.getDataList()
            },
            // 当前页
            currentChangeHandle(val) {
                this.pageIndex = val
                // this.getDataList()
            },
            // 多选框选中数据
            handleSelectionChange(selection) {
                //获取所有选中项的gh(工号)属性的值
                this.indexs = selection.map(item => item)
                //获取所有选中项数组的长度
                this.selectedNum = selection.length
                this.DetailsForm.length=0
                this.indexs.forEach((item,index) =>{
                    this.DetailsForm[index]=item
                })


            },
        },
        watch: {
            'id': {//监听第一个选择器的值变化
                handler(val, oldVal) {
                    this.getDataPengList(val);
                },

            }
        }
    }
</script>

<style scoped>
    .mod-config{
        width: auto;
        bottom: 20px;
        right: 0px;
        left:0px;
        top: 73px;
        position: absolute;
        margin-left: 240px;
        box-sizing: border-box;
    }
    /deep/.sousuo .el-select{
        background: none !important;
        border: none !important;
    }

    /*.el-input.el-input--suffix {*/
    /*// 修改背景颜色、字体颜色、边框、宽高*/
    ::v-deep.sousuo .el-select .el-input__inner {
        background: #F1F7F5 !important;
        border: 1px solid #E5E5E5;
        color: #333333;
        height: 41px;
        width: 268px;
    }

    /deep/
    .sousuo .el-select .el-input.is-focus .el-input__inner{
        border: 1px solid #166D66 !important;
    }

    /deep/.sousuo.el-select .el-select .el-input__inner:focus {
        border: 1px solid #166D66 !important;
    }
    /*右侧图标样式  \e790下箭头图标*/
    /deep/.el-select .el-input .el-select__caret.el-input__icon::before {
        content: "\e790";
        color: #134453;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    /*如果不设置图标下拉时是反的*/
    /deep/.el-select .el-input .el-select__caret {
        transform: rotateZ(0deg);
    }
    /deep/.el-tabs__item.is-active{
        color: #333333 !important;
    }
    /deep/.el-tabs__item {
        color: #999999 !important;
        padding: 0 40px;
        height: 45px;
        font-size: 20px;
    }
    /deep/.el-tabs__active-bar{
        height: 4px;
    }
    /deep/.el-tabs__nav{
        width: 100%;
        border-bottom: 1px solid #E5E5E5;
    }
    /deep/
    .el-tabs--top .el-tabs__item.is-top:nth-child(2){
        padding-left: 20px ;
    }
    .xiangqing{
        width: 86px;
        height: 33px;
        background: #E7FBEF;
        border: 2px solid #18C15C;
        border-radius: 4px;
        padding: 0;
        float: right;
        margin: 20px 23px 0 24px;
        font-weight: 500;
        color: #009E4F;
    }
    .xiangqing:hover{
        color: #166D66;
    }
    /deep/.el-icon-sold-out:before{
        /*width: 18px !important;*/
        /*height: 18px !important;*/
        content: url("../../assets/basegreenhouse/ic_export.png");
        vertical-align: middle;
        display: inline-block;
    }
    /deep/.el-table-column{
        background-color: #f6f6f6 !important;
    }
    /deep/.el-table th {
        padding: 0;
        margin: 0;
        height: 50px;
        background-color: #f6f6f6 !important;
    }
    /deep/.el-table tr{
        padding: 0;
        margin: 0;
        height: 50px;
    }
    /deep/ .el-table tbody tr:hover > td {
        background-color: #fdfdfd !important;
    }

    /deep/.el-table .el-table__cell{
        padding: 0;
        margin: 0;
    }
    .tabLst-title {
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #1A99F2;
        cursor: pointer;
        margin-bottom: 10px;
    }
    /deep/.el-pager li.active{
        color: #009E4F !important;
    }
    .el-pagination{
        margin-top: 36px;
        text-align: center;
    }



</style>
